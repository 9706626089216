<template>
  <div class="page">
    <dv class="title">2025年市机关事务局“十件暖心实事”评选</dv>
    <div class="intro">
      <div class="intro-t">
        为深入贯彻落实党的二十届三中全会精神，进一步深化机关服务保障方式创新，提升服务品质，当好机关运行保障“甬有红管家”，努力把干部职工关注的“身边小事”变成“暖心实事”，进一步增强干部职工幸福感、获得感，市机关事务局在深入调研和征求意见的基础上，初步选出2025年度15件“暖心实事”，现诚邀您参与评选。后续我们将根据投票结果，确定实施2025年机关事务“十件暖心实事”。
      </div>
      <div class="intro-b"><img src="./img/vote.png" alt="" /></div>
    </div>
    <div class="list" v-if="isVote">
      <div class="voteTitle">请点击文字选项进行投票，共需选择10项</div>
      <div
        class="box"
        v-for="(item, index) in oList"
        :key="index"
        @click="selectBox(item)"
      >
        <div :class="['item', { optionSelect: item.isSelect }]">
          {{ item.optionTitle }}
          <img v-if="item.isSelect" src="./img/sure.png" alt="" />
        </div>
      </div>
    </div>
    <div class="finished" v-else>您已投票</div>
    <div class="submit" v-if="isVote" @click="submitVote">提交</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import { submitVoteURL, lookVoteURL } from "./api.js";
import { gloabalCount, toRegister } from "@/utils/common";
import { getHashParam } from "@/utils/utils";

export default {
  name: "voteList",

  data() {
    return {
      selectList: [],
      isVote: true,
      userVote: {},
      isStop: 1,
      oList: [
        {
          id: "1",
          isSelect: false,
          optionTitle:
            "1、建成东部新城托育中心:预计于年底前建成,满足干部职工1.5-3岁的幼儿托育需求。",
        },
        {
          id: "2",
          isSelect: false,
          optionTitle:
            "2、开展“送你一碗生日面”活动:联合市总工会,为干部职工送上生日暖心面。",
        },
        {
          id: "3",
          isSelect: false,
          optionTitle:
            "3、增设食堂特色窗口:开设食堂夜间加班餐窗口,增强餐饮服务保障。",
        },
        {
          id: "4",
          isSelect: false,
          optionTitle:
            "4、试行暑期食堂儿童套餐:试行暑期食堂儿童套餐,丰富就餐选择。",
        },
        {
          id: "5",
          isSelect: false,
          optionTitle:
            "5、更新健身房设施设备:丰富健身器材种类,为干部职工提供更好的健身条件。",
        },
        {
          id: "6",
          isSelect: false,
          optionTitle:
            "6、试行体检报告专家咨询活动:定期邀请三甲医院专家驻点,提供报告解读和健康咨询服务。",
        },
        {
          id: "7",
          isSelect: false,
          optionTitle:
            "7、开展幼教公益早教活动:以亲子活动、公益讲座等形式,传递早教知识。",
        },
        {
          id: "8",
          isSelect: false,
          optionTitle:
            "8、实施部分老旧办公楼改造提升工作:分步实施部分办公楼立面、电梯、停车设施等维修改造。",
        },
        {
          id: "9",
          isSelect: false,
          optionTitle:
            "9、推行工作专班办公家具配备标准化:依托宁波公物仓资源,为工作专班配置标准化办公家具设备。",
        },
        {
          id: "10",
          isSelect: false,
          optionTitle:
            "10、试行重要会议活动保障机器人服务:充分挖掘宁波人形机器人资源,创新推动服务保障智能化。",
        },
        {
          id: "11",
          isSelect: false,
          optionTitle:
            "11、拓展8号楼区域停车位:进一步挖掘周边停车位资源,积极缓解干部职工停车难问题。",
        },
        {
          id: "12",
          isSelect: false,
          optionTitle:
            "12、开设公务用车驾驶员驿站:设置微波炉、饮水机、书报架等设施,为驾驶员提供舒适便利的学习、休息场所。",
        },
        {
          id: "13",
          isSelect: false,
          optionTitle:
            "13、增加行政中心周边共享单车停靠点:在地铁、公交站点附近增设点位,助力干部职工绿色通勤。",
        },
        {
          id: "14",
          isSelect: false,
          optionTitle:
            "14、增设门岗外便民快递储物柜:统一存放快递外卖,便利干部职工储物取物。",
        },
        {
          id: "15",
          isSelect: false,
          optionTitle:
            "15、实时共享雨伞便民服务:在会议中心、食堂等场所设置雨伞点,便利干部职工雨天出行。",
        },
      ],
    };
  },
  watch: {
    oList: {
      handler: function (newUser, oldUser) {},
      deep: true, //
    },
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  async created() {},
  mounted() {
    this.lookVote();
  },
  methods: {
    //
    selectBox(item) {
      if (this.selectList.length <= 9) {
        item.isSelect = !item.isSelect;
        this.selectList = [];
        this.oList.forEach((ele) => {
          if (ele.isSelect) {
            this.selectList.push(ele);
          }
        });
      } else {
        if (item.isSelect) {
          item.isSelect = !item.isSelect;
          this.selectList = [];
          this.oList.forEach((ele) => {
            if (ele.isSelect) {
              this.selectList.push(ele);
            }
          });
        } else {
          this.$toast({
            message: "请选择10项！",
            duration: 500,
          });
        }
      }

      console.log(this.selectList, "00000000000");
    },
    async lookVote() {
      let params = {
        userId: getHashParam("userId") || "1777286254038163457",
      };
      let res = await this.$axios.get(`${lookVoteURL}`, {
        params: params,
      });
      if (res.code === 200) {
        this.userVote = res.data;
        if (this.userVote.userId && this.userVote.chosenIds) {
          this.isVote = false;
        }
      } else {
      }
    },
    //
    async submitVote() {
      if (this.selectList.length <= 0) {
        this.$toast({
          message: "请选择选项！",
          duration: 500,
        });
        return;
      }
      if (this.selectList.length != 10) {
        this.$toast({
          message: "请选择10项！",
          duration: 500,
        });
        return;
      }
      if (this.isStop != 1) {
        return;
      }
      this.isStop = 0;
      let arr = this.selectList.map((item) => item.id);
      let params = {
        chosenIds: arr.join(","),
        userId: getHashParam("userId") || "1777286254038163457",
      };
      let res = await this.$axios.post(`${submitVoteURL}`, params);
      if (res.code === 200) {
        await this.lookVote();
      } else {
      }
      this.isStop = 1;
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  padding: 50px 0 0;
  min-height: 100vh;
  box-sizing: border-box;
  background: #ffdede;
  .title {
    padding-top: 44px;
    padding: 32px;
    font-size: 36px;
    font-weight: bold;
    color: #1a1c34;
    line-height: 44px;
    text-align: center;
    word-wrap: break-word;
    word-break: break-all;
  }
  .intro {
    padding: 32px;
    margin: 50px 0;
    .intro-t {
      font-size: 32px;
      text-indent: 2em;
      font-weight: 400;
      color: #333333;
      line-height: 52px;
      word-wrap: break-word;
      word-break: break-all;
      margin-bottom: 10px;
    }
    .intro-b {
      img {
        width: 100%;
      }
    }
  }
  .list {
    padding: 32px;
    .voteTitle {
      font-size: 32px;
      font-weight: bold;
      color: #333333;
      line-height: 44px;
      margin-bottom: 24px;
      word-wrap: break-word;
      word-break: break-all;
    }
    .item {
      position: relative;
      box-sizing: border-box;
      border-radius: 8px;
      border: 2px solid #c9cacb;
      overflow: hidden;
      margin-bottom: 44px;
      font-size: 32px;
      font-weight: 400;
      color: #333333;
      line-height: 52px;
      padding: 14px 164px 14px 32px;
      word-wrap: break-word;
      word-break: break-all;
      position: relative;
    }
    .optionSelect {
      border: 2px solid #d9332a;
      position: relative;
      img {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 70px;
      }
    }
  }
  .submit {
    width: 100%;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d9332a;
    color: #ffffff;
    letter-spacing: 4px;
    font-weight: bold;
  }
  .finished {
    font-size: 32px;
    color: #333333;
    line-height: 52px;
    padding-bottom: 50px;
    font-weight: bold;
    text-align: center;
  }
}
</style>
